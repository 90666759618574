<template>
  <div>
    <el-container>
      <el-aside class="home-aside" width="240px" v-if="$route.path !== '/home/payment'">
        <el-menu :router="true" :default-active="currentIndex" @select="handleMenuSelect">
          <template v-for="(item, index) in menus">
            <el-submenu v-if="item.children" :key="index" :index="'' + (index + 1)">
              <template slot="title">
                <div class="menu-item" :style="item.style">
                  <p><i class="menu_icon" :class="item.icon"></i>{{ item.title }}</p>
                </div>
              </template>
              <el-menu-item-group>
                <template slot="title"></template>
                <template v-for="(i, j) in item.children">
                  <el-menu-item :key="j" :index="(index + 1) + '-' + (j + 1)" :route="i.route"><i class="menu_icon"
                      :class="i.icon"></i>{{ i.title }}</el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :key="index" :index="'' + (index + 1)" :route="item.route" :class="{ 'custom-menu-item': item.isOpen }">
              <template slot="title">
                <div class="menu-item" :style="item.style">
                  <p><i class="menu_icon" :class="item.icon"></i>{{ item.title }}</p>
                </div>
              </template>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-main class="home-content">
        <div style="flex: 1; overflow-y: auto">
          <keep-alive include="record">
            <router-view></router-view>
          </keep-alive>
        </div>
        <div style="
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #cccccc;
            font-size: 16px;
            font-weight: 400;
          ">
          {{ currenrYear }} Extracurricular Activity Excellence | All Rights Reserved
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import "../assets/common/common.css";
import "../assets/common/font.css";
import { getUserType, isTestUser, getUserId, setTargetType } from "../utils/store";
export default {
  name: "Home",
  data() {
    return {
      currenrYear: '',
      currentIndex: '',
      user_type: getUserType(),

      menus: [
        
        
        
        {
          selected: false,
          title: "My EAE",
          icon: "menu_icon_12",
          children: [
            {
              selected: false,
              title: "My EAE Passes",
              route: "/home/myPasses",
              icon: "menu_icon_13",
            },
            {
              selected: false,
              title: "My Activity Programs",
              route: "/home/programs",
              icon: "menu_icon_11",
            },
            {
              selected: false,
              title: "My EAE Award Entries",
              route: "/home/reviewEntries",
              icon: "menu_icon_5",
            },
            {
              selected: false,
              title: "My Activity Sandbox",
              route: "/home/activities",
              icon: "menu_icon_8",
            },
            {
              selected: false,
              title: "My Profile",
              route: "/home/myProfile",
              icon: "menu_icon_12",
            }
          ]
        },
        {
          selected: false,
          title: "EAE Resources",
          route: "/home/reviewEntries",
          icon: "menu_icon_4",
          children: [
            
            // {
            //   selected: false,
            //   title: "My Subscription",
            //   route: "/home/registration",
            //   icon: "menu_icon_6",
            // },
            {
              selected: false,
              title: "EAE Award Categories",
              route: "/home/activityCategory",
              icon: "menu_icon_7",
            },
            
            {
              selected: false,
              title: "Activity Guidance",
              route: "/home/activityAdvices",
              icon: "menu_icon_9",
            },
            {
              selected: false,
              title: "Activity Ideas",
              route: "/home/activityOptions",
              icon: "menu_icon_10",
            },
          ]
        },
        {
          selected: false,
          title: "Messages",
          route: "/home/messages",
          icon: "menu_icon_14",
        },
      ],
    };
  },
  created() {
    setTargetType('student')
    this.currenrYear = new Date().getFullYear()

    if (getUserType() == 'schoolAdmin') {
      this.menus = [
        {
          selected: false,
          title: "News & Updates",
          route: "/home/news&updates",
        },
        {
          selected: false,
          title: "Student Programs ",
          route: "/home/schoolRegistrations",
          children: [
            {
              selected: false,
              title: "School Registration",
              route: "/home/studentRegistrations",
            },
            {
              selected: false,
              title: "Services & Programs",
              route: "/home/studentPrograms",
            },
          ]
        },
        {
          selected: false,
          title: "School Programs",
          route: "/home/schoolPrograms",
          children: [
            {
              selected: false,
              title: "School Registration",
              route: "/home/schoolRegistrations",
            },
            {
              selected: false,
              title: "Services & Programs",
              route: "/home/schoolPrograms",
            },
          ]
        },
        {
          selected: false,
          title: "Teacher Management",
          route: "/home/teacherManagement",
        },
        {
          selected: false,
          title: "Project List",
          route: '/home/projectList',
        },
      ];
      return;
    }

    if (getUserType() === "admin") {
      this.menus = [
        {
          selected: false,
          title: "AccountForms",
          route: "/home/adminform",
        },
        {
          selected: false,
          title: "EntryList",
          route: "/home/admin-enties",
        },
      ];
      return;
    } else if (getUserType() === "advisor") {
      this.menus = [
        {
          selected: false,
          title: "Registrations",
          route: "/home/accountapps",
        },
        {
          selected: false,
          title: "Category List",
          route: '/home/newCategoryList',
        },
        {
          selected: false,
          title: "Student List",
          route: '/home/advisorStudentList',
        },
        {
          selected: false,
          title: "Certificate List",
          route: '/home/advisorCertificateList',
        },
        {
          selected: false,
          title: "Project List",
          route: '/home/projectList',
        },
      ];
      if (getUserId() == '62ba5a98b4ba942980737ead') {
        this.menus.push({
          selected: false,
          title: "Entry List",
          route: '/home/advisorStudentEntryList',
        },)
      }
      return;
    }
    if (getUserType() == "student") {
      let eaeActivities = {
        selected: true,
        title: "EAE Activities",
        icon: "menu_icon_1",
        isOpen: true,
        style: {
          fontWeight: 'bold',
          color: 'var(--c-primary)',
          '&:hover': {
            color: 'var(--c-primary)',
          },
        },
        children: [
        {
            selected: false,
            title: "Activity Passes",
            route: "/home/passes",
            icon: "menu_icon_2",
          },  
        {
            selected: false,
            title: "Activity Store",
            route: "/home/store",
            icon: "menu_icon_3",
          },
          
        ]
      }
      this.menus.unshift(eaeActivities)

      let news = {
        selected: false,
        title: "News & Updates",
        route: "/home/news&updates",
        icon: "menu_icon_1",
      }
      this.menus.unshift(news)
    }
    this.$globalMessage.$on('homeNavMenuChanged', (res) => {
      this.homeNavMenuChanged(res)
    })
  },
  mounted() {
    this.currentIndex = '' + sessionStorage.getItem('currentIndex') || '1';
  },
  methods: {

    homeNavMenuChanged(route_path) {
      let selectedIndex = -1
      for (let i = 0; i < this.menus.length; i++) {
        let menu = this.menus[i]
        menu.selected = false
        let selected = (menu.route == route_path)
        if (selected == true) {
          selectedIndex = i
        }
      }
      this.menus[selectedIndex].selected = true
      this.currentIndex = '' + (selectedIndex + 1)
      this.$forceUpdate()
    },

    handleMenuSelect(index) {
      console.log(index, '=-=-=-')
      this.currentIndex = index;
      sessionStorage.setItem('currentIndex', index);
    },
  },
};
</script>

<style scoped lang="less">
::-webkit-scrollbar {
  display: none;
}

.home-content {
  height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-aside {
  overflow-y: auto;
  height: calc(100vh - 50px);
  background: #F9F9F9;
}

.menu-item {
  display: flex;
  align-items: center;
}

.menu_icon {
  display: inline-block;
  margin-right: 4px;
  width: 24px;
  height: 24px;
}

.menu_icon_1 {
  background: url("../assets/image/menu_icon/menu_icon_1.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_2 {
  background: url("../assets/image/menu_icon/menu_icon_2.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_3 {
  background: url("../assets/image/menu_icon/menu_icon_3.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_4 {
  background: url("../assets/image/menu_icon/menu_icon_4.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_5 {
  background: url("../assets/image/menu_icon/menu_icon_5.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_6 {
  background: url("../assets/image/menu_icon/menu_icon_6.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_7 {
  background: url("../assets/image/menu_icon/menu_icon_7.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_8 {
  background: url("../assets/image/menu_icon/menu_icon_8.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_9 {
  background: url("../assets/image/menu_icon/menu_icon_9.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_10 {
  background: url("../assets/image/menu_icon/menu_icon_10.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_11 {
  background: url("../assets/image/menu_icon/menu_icon_11.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_12 {
  background: url("../assets/image/menu_icon/menu_icon_12.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_13 {
  background: url("../assets/image/menu_icon/menu_icon_13.png") 100% 100%;
  background-size: 24px 24px;
}

.menu_icon_14 {
  background: url("../assets/image/menu_icon/menu_icon_14.png") 100% 100%;
  background-size: 24px 24px;
}

.custom-menu-item {
  background-color: var(--c-primary) !important;
}

.el-menu-item.is-active {
  color: #FF6450 !important;
}

.el-submenu.custom-menu-item .el-submenu__title {
  background-color: var(--c-primary) !important;
  color: white !important;
}

.el-submenu.custom-menu-item .el-submenu__title:hover {
  background-color: var(--c-primary) !important;
}
</style>